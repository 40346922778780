<template>
    <section class="card-product d-flex px-3 cr-pointer">
        <div class="bar-card" :class="`${id_producto == producto.id ? 'bg-general' : ''}`" />
        <div class="col px-0 px-2" @click="setProducto">
            <div class="row mx-0 h-100 info-product">
                <div class="col-auto d-middle px-2 position-relative">
                    <div class="mn-pill bg-green f-11 text-white position-absolute" style="width:17px;height:17px;top:10px;right:0px;">
                        {{ agregarSeparadoresNumero(producto.en_carrito) }}
                    </div>
                    <img :src="producto.imagen" class="br-8 obj-cover" width="80" height="80" />
                </div>
                <div class="col px-0 py-2">
                    <div class="row mx-0 text-general">
                        <div class="col">
                            <p class="f-12 nombre-product">
                                {{ producto.nombre }}
                            </p>
                        </div>
                        <el-tooltip v-show="producto.venta_sin_stock" placement="bottom" content="Venta sin stock" effect="light">
                            <div class="col-auto px-0">
                                <i class="icon-cash-lock-open f-15 text-general mr-3" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="row mx-0 align-items-center">
                        <p class="col px text-gris2 f-12 text-gris2">
                            {{ producto.presentacion }}
                        </p>
                        <div :class="`mn-pill ${producto.stock_bajo ? 'bg-alert' : 'bg-general'}  text-white f-11 mr-2 ml-auto`">
                            {{ agregarSeparadoresNumero(producto.cant_stock) }}
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center mt-1">
                        <p class="col px text-general text-gris2">
                            <span v-if="producto.promocion" class="f-500 f-15">{{funValor(producto.data_promocion.promo_valor) }}</span>
                            <span v-else class="f-500 f-15">{{ funValor(producto.valor) }}</span>
                            <small class="ml-1">App</small>
                        </p>
                        <div class="mn-pill bg-gris2 text-white f-11 mr-2">
                            <i class="icon-rotation f-11" />
                            {{agregarSeparadoresNumero(producto.rotacion,1)}}
                        </div>
                    </div>
                    <div v-show="tienda.editar_precios_inventario" class="row mx-0 align-items-center mt-1">
                        <p class="col px text-gris2 text-gris2">
                            <span class="f-500 f-15">{{ funValor(producto.valor_tienda) }}</span>
                            <small class="ml-1">Vendedor</small>
                        </p>
                        <div v-if="producto.ganancia > 0" class="mn-pill bg-green text-white f-11 mr-2">
                            <i class="icon-up f-11" />
                            {{ agregarSeparadoresNumero(producto.ganancia,1) }}%
                        </div>
                        <div v-else class="mn-pill bg-red text-white f-11 mr-2">
                            <i class="icon-down f-11" />
                            {{ agregarSeparadoresNumero(producto.ganancia,1) }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props:{
        producto:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return {

        }
    },
    computed:{
        ...mapGetters({
            tiendaStore: 'tiendas/info_cabecera/tienda',
            id_producto: 'productos/productos/id_producto',
        }),
        tienda(){
            let rol = this.$usuario.rol
            if(rol === 1 || rol === 3){
                return this.tiendaStore
            }
            return this.$tienda
        },
        tipoImpuesto(){
            return this.tienda.tipo_impuesto
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
    },
    methods:{
        funValor(value){
            let valor  = 0
            if(this.tipoImpuesto === 0){
                valor = value
            }
            if(this.tipoImpuesto === 1){
                valor = value / (1 + this.impuesto / 100)
            }
            if(this.tipoImpuesto === 2 || this.tipoImpuesto === 3){
                valor = value
            }

            let rol = this.$usuario.rol
            if(rol === 1 || rol === 3){
                return this.convertMoney(valor,this.tienda.idm_moneda)
            }
            return this.convertMoneyTendero(valor,this.tienda.idm_moneda)
        },
        setProducto(){
            this.$store.commit('productos/productos/set_id_producto', this.producto.id)
        },
    }

}
</script>
<style lang="scss" scoped>
.card-product{
    .bar-card{
        width: 8px;
        border-radius: 8px;
    }
    .info-product{
        box-shadow: 0px 3px 6px #0000000D;
        border-radius: 8px;
        .nombre-product{
            height: 38px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: pre-line;
            line-height: 19px;
        }
    }
}
</style>
